import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../layout/navbar";
import api from "../api";

function AccountSettings() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [availableTokens, setAvailableTokens] = useState(0);
  const [tokensTotal, setTokensTotal] = useState(100);
  const [customerPortalUrl, setCustomerPortalUrl] = useState("");
  const [subscriptionEnded, setSubscriptionEnded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);

  function getAvailableTokens() {
    api
      .get(`/accounts/usage/usage_left/tokens/`)
      .then((response) => {
        // Set the feature state to the response data
        console.log("response.data : ", response.data);
        if (response.data.usage) {
          console.log("available tokens : ", response.data.usage.amount_left);
          setAvailableTokens(response.data.usage.amount_left);
        } else {
          console.log(response.data.error);
        }
      })
      .catch((error) => {
        console.error("error loading tokens : " + error);
      });
  }

  function getTokensTotal() {
    api
      .get(`/accounts/features/tokens/`)
      .then((response) => {
        // Set the feature state to the response data
        console.log("response.data : ", response.data);
        if (response.data.feature) {
          console.log("tokens limit : ", response.data.feature.limit);
          setTokensTotal(response.data.feature.limit);
        } else {
          console.log(response.data.error);
        }
      })
      .catch((error) => {
        console.error("error loading tokens limit : " + error);
      });
  }

  function getCustomerPortalUrl() {
    api
      .get(`/accounts/subscriptions/create-customer-portal/`)
      .then((response) => {
        console.log("response.data : ", response.data);
        if (response.data.customer_portal_url) {
          console.log(
            "customer portal url : ",
            response.data.customer_portal_url
          );
          setCustomerPortalUrl(response.data.customer_portal_url);
        } else {
          console.log(response.data.error);
        }
      })
      .catch((error) => {
        // when you get customer not found error it means the subscription was never created 
        // the first time the user regidtered, this is normal, the user should always register
        // first before they have access to account settings page to be on the 
        // safe side of things you can implement an activate subscription button + backend
        console.error("error loading customer portal url : " + error.response.data.error);
        setSubscriptionEnded(true);
      });
  }

  function checkSubscriptionStatus() {
    api
      .get(`/accounts/subscriptions/current/`)
      .then((response) => {
        console.log("current subscription : ", response.data);
        // check if end_date < today date => subscription ended
        const endDate = new Date(response.data.end_date);
        const today = new Date();
        console.log("today : ", today);
        console.log("end date : ", endDate);
        if (endDate.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0)) {
          console.log("subscription ended");
          setSubscriptionEnded(true);
        } else {
          console.log("subscription active");
          setSubscriptionEnded(false);
        }
      })
      .catch((error) => {
        console.error("error loading subscription status : " + error);
      });
  }

  function getMember() {
    api
      .get(`/accounts/current/`)
      .then((response) => {
        console.log("response.data : ", response.data);
        setFirstName(response.data.first_name);
        setLastName(response.data.last_name);
        setUserEmail(response.data.email);
      })
      .catch((error) => {
        console.error("error loading member : " + error);
      });
  }

  function editMember() {
    setIsSaveLoading(true);
    api
      .post(`/accounts/current/edit/`, {
        first_name: firstName,
        last_name: lastName,
      })
      .then((response) => {
        console.log("response.data : ", response.data);
      })
      .catch((error) => {
        console.error("error editing member : " + error);
      })
      .finally(() => {
        setIsSaveLoading(false);
      });
  }

  useEffect(() => {
    getAvailableTokens();
    getTokensTotal();
    getCustomerPortalUrl();
    checkSubscriptionStatus();
    getMember();

    // Create a new script element for Hubspot 
    const script = document.createElement('script');
    script.src = "//js-eu1.hs-scripts.com/145125311.js";
    script.async = true;
    script.defer = true;
    script.id = 'hs-script-loader';

    // Append the script to the body or head
    document.body.appendChild(script);

    // Cleanup: Remove the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };	  
	  
  }, []);

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    editMember();
  };

  const handleChangePasswordSubmit = async (event) => {
    event.preventDefault();

    // Password validation logic
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      console.log("Passwords do not match");
    } else {
      // Passwords are valid, proceed with the password change request
      setPasswordError("");
      setConfirmPasswordError("");

      api
        .post("/accounts/current/change-password/", {
          current_password: currentPassword,
          new_password: password,
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("Password changed successfully");
            setPasswordChanged(true);
          } else {
            console.error("Error changing passwords");
            setCurrentPasswordError("Error changing passwords");
          }
        })
        .catch((error) => {
          console.error("Error changing password : " + error);
          setCurrentPasswordError(error.response.data.error);
        });
    }
  };

  const progress = ((tokensTotal - availableTokens) / tokensTotal) * 100;

  const handleSubscriptionSettingsClick = (event) => {
    event.preventDefault();
    window.location.href = customerPortalUrl;
  };

  const handleReactivateSubscriptionClick = (event) => {
    event.preventDefault();
    setIsLoading(true);
    api
      .get("/accounts/subscriptions/create_checkout_session/")
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          window.location.href = response.data.checkout_session_url;
        } else {
          console.error("Error creating checkout session");
          alert("Error creating checkout session");
        }
      })
      .catch((error) => {
        console.error("error reactivating subscription : " + error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Navbar />
      <div className="section pt-5">
        <h1 className="title">Account Settings</h1>
        <div className="columns">
          <div className="column is-one-third">
            <div className="box">
              <h2 className="subtitle">
                <i className="fas fa-user"></i> Personal Information
              </h2>
              <div>
                <div className="field">
                  <label className="label">First Name</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      value={firstName}
                      onChange={handleFirstNameChange}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Last Name</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      value={lastName}
                      onChange={handleLastNameChange}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Email</label>
                  <div className="control">
                    <input
                      className="input"
                      type="email"
                      disabled
                      value={userEmail}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <button
                      onClick={handleSubmit}
                      className={`button is-primary ${
                        isSaveLoading ? "is-loading" : ""
                      }`}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <h2 className="subtitle">
                <i className="fas fa-lock"></i> Change Password
              </h2>
              <form onSubmit={handleChangePasswordSubmit}>
                <div className="field">
                  <label className="label">Current Password</label>
                  <div className="control">
                    <input
                      className="input"
                      type="password"
                      onChange={handleCurrentPasswordChange}
                    />
                    {currentPasswordError && (
                      <p className="help is-danger">{currentPasswordError}</p>
                    )}
                  </div>
                </div>
                <div className="field">
                  <label className="label">New Password</label>
                  <div className="control">
                    <input
                      className={`input ${passwordError ? "is-danger" : ""}`}
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    {passwordError && (
                      <p className="help is-danger">{passwordError}</p>
                    )}
                  </div>
                </div>
                <div className="field">
                  <label className="label">Confirm New Password</label>
                  <div className="control">
                    <input
                      className={`input ${
                        confirmPasswordError ? "is-danger" : ""
                      }`}
                      type="password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    {confirmPasswordError && (
                      <p className="help is-danger">{confirmPasswordError}</p>
                    )}
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <button className="button is-primary">
                      Change Password
                    </button>
                    {passwordChanged && (
                      <div className="notification is-success mt-3">
                        Password changed successfully!
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="box">
              <h2 className="subtitle">
                <i className="fas fa-envelope"></i> Subscription Settings
              </h2>
              {subscriptionEnded && (
                <button
                  className={`button is-primary mb-3 ${
                    isLoading ? "is-loading" : ""
                  }`}
                  onClick={handleReactivateSubscriptionClick}
                >
                  Reactivate Subscription
                </button>
              )}
              <p>
                Manage your subscription settings by visiting the{" "}
                <a
                  href={customerPortalUrl}
                  onClick={handleSubscriptionSettingsClick}
                >
                  subscription settings
                </a>{" "}
                page.
              </p>
            </div>
            <div className="box">
              <h2 className="subtitle">
                <i className="fas fa-coins"></i> Token Usage
              </h2>
              <p>
                You have used {tokensTotal - availableTokens} out of{" "}
                {tokensTotal} tokens this month.
              </p>
              <progress
                className="progress is-primary mt-2"
                value={progress}
                max="100"
              >
                {progress}%
              </progress>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountSettings;
